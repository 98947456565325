<template>
  <div>
    <b-card>
      <b-card-text>
        <b-row v-for="(item, index) in arrayDiscountedEvents" :key="index">
          <b-col cols="12" md="4">
            <b-form-group label="Event*">
              <v-select
                v-model="item.event_id"
                :reduce="(event) => event.event_id"
                placeholder="Select Event/Movie"
                label="event_name"
                value="event_id"
                :options="eventListData"
              >
                <template slot="selected-option" slot-scope="option">
                  <div class="flex">
                    {{ option.event_name }} <b> ({{ option.type }}) </b>
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <span class="fa" :class="option.icon"></span>
                  {{ option.event_name }}<b>({{ option.type }}) </b>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Discount Percent">
              <b-form-input
                v-model="item.discount_percent"
                type="number"
                id="input-default"
                placeholder="Discount Percent"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-button
              class="mt-2"
              size="sm"
              variant="outline-info"
              @click="onItemRemove(index)"
              >Remove</b-button
            >
          </b-col>
        </b-row>
        <b-badge variant="primary" pill @click="onAddEventsMovies"
          >Add Events/Movies</b-badge
        >
        <b-row>
          <b-col class="mt-3" md="6" offset-md="3">
            <b-button block variant="primary" @click="onSubmit"
              >Submit</b-button
            >
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

import {
  AddUpdatePassDiscountService,
  GetPassDiscountService,
  GetEventListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  data() {
    return {
      pass_id: null,
      eventListData: [],
      arrayDiscountedEvents: [],
    };
  },
  components: {
    vSelect,
    flatPickr,
    quillEditor,
  },
  async beforeMount() {
    if (this.$route.query && this.$route.query.pass_id) {
      this.pass_id = this.$route.query.pass_id;
      await this.getEventList();
      await this.getPassDiscountsById(this.$route.query.pass_id);
    } else {
      window.history.go(-1);
    }
  },
  methods: {
    onItemRemove(index) {
      this.arrayDiscountedEvents.splice(index, 1);
    },
    async onAddEventsMovies() {
      let obj = {
        event_id: null,
        discount_percent: 0,
      };
      this.arrayDiscountedEvents.push(obj);
    },
    async onSubmit() {
      try {
        let payload = {
          pass_id: this.pass_id,
          arrayDiscountedEvents: this.arrayDiscountedEvents,
        };

        const response = await AddUpdatePassDiscountService(payload);
        if (response.data.status) {
          this.$router.push({
            name: "passlist",
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getPassDiscountsById(pass_id) {
      try {
        const response = await GetPassDiscountService({
          pass_id: this.pass_id ? this.pass_id : null,
        });

        if (response.data.status) {
          if (response.data && response.data.Records) {
            this.arrayDiscountedEvents = response.data.Records;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getEventList() {
      this.eventListData = [];
      try {
        const response = await GetEventListService({
          limit: 5000,
          // country_id: this.form.country_id,
        });
        if (response.data.status) {
          this.eventListData = response.data.Records;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
